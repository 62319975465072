@import "../generic/breakpoints";
@import "../generic/globalClasses";


.sensor-list {
    list-style: none;
    padding: 0;
}

.sensor-list .sensor-item {
    padding: 10px;
    transition: background-color 0.3s ease;
    /* Transición suave de color de fondo */
}

.sensor-list .sensor-item:nth-child(odd) {
    // background-color: #f0f0f0;
    /* Color para elementos impares */
}

.sensor-list .sensor-item:nth-child(even) {
    //background-color: $table-hover-bg;
    /* Color para elementos pares */
}

#sensorsGallery {

    /*  display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 10px;
    overflow: auto;
    overflow-y: auto;
    max-height: 100%;
    height: 85%;
*/
    /*
    iframe {
        max-width: 100%;
        height: auto;
    }
    */
}

#sensorsContainer {
    h2 {
        text-align: center;
    }

}

@media ((min-width: $mobileS ) and (max-width: $mobile)) {

    #sensorsContainer {

        position: absolute;
        right: 0%;
        top: 7%;
        height: 30vh;
        width: 50vw;
        z-index: 1;
        margin-right: 1.5rem !important;
    }
}

@media((min-width: $mobile) and (max-width: $tablet )) {


    #sensorsContainer {
        position: absolute;
        right: 0%;
        top: 7%;
        height: 30vh;
        width: 50vw;
        z-index: 1;
        margin-right: 1.5rem !important;
    }
}

@media ((min-width: $tablet) and (max-width:$desktop)) {


    #sensorsContainer {
        position: absolute;
        right: 0%;
        top: 10%;
        height: 40vh;
        width: 50vw;
        z-index: 1;
        margin-right: 1.5rem !important;
    }
}

@media ((min-width: $desktop) and (max-width:$large-desktop)) {


    #sensorsContainer {
        position: absolute;
        right: 0%;
        top: 10%;
        height: 54vh;
        width: 21.7vw;
        z-index: 1;
        margin-right: 1.5rem !important;

        z-index: 1;



        .card-body {
            padding: 0 1.5rem;
        }
    }

    #sensorsGallery {
        display: flex;
        flex-direction: column;
        /*
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(233px, 1fr));
        grid-gap: 25px 10px;
        overflow: auto;
        overflow-y: auto;
        max-height: 100%;
        height: 75%;
        grid-template-rows: 100%;
*/
        /*
      

        div {
            height: auto;


            iframe {
                max-width: 100%;
                height: 90%;

            }
        }
*/
    }
}