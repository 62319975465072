.iframe-wrapper {
    width: 270px;
    /* O un ancho fijo como 300px */
    position: relative;
    border: 1px solid #ccc;
    /* Opcional para estética */
    overflow: hidden;
    /* Ocultar desbordamiento del contenido */
    resize: horizontal;

    /* Permitir redimensionar el contenedor */
    * {
        pointer-events: all; // Aplica a todos los hijos y descendientes directos.
    }

    * * {
        pointer-events: all; // Aplica a todos los nietos y descendientes de segundo nivel.
    }
}

.iframe-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: top left;
    transform: scale(1);
    /* Escalado inicial */
    width: 800px;
    /* Ancho original del contenido del iframe */
    height: 600px;
    /* Altura original del contenido del iframe */
    border: none;

    * {
        pointer-events: all; // Aplica a todos los hijos y descendientes directos.
    }

    * * {
        pointer-events: all; // Aplica a todos los nietos y descendientes de segundo nivel.
    }
}