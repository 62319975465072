#details-element {
    width: 100%;
    //pointer-events: none;
    height: 10vh;
    display: flex;
    flex-direction: column;
    height: 80%;

    tbody {
        tr {
            width: 100%;
            display: flex;
            height: auto;

            >* {
                flex: 1;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

            }

            td {
                display: flex;
                flex-direction: column;
                flex: 1;
                overflow: hidden;
                margin-left: 1rem;
                white-space: nowrap;

                >* {
                    flex: 1;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                }
            }

            th:hover::after {

                content: attr(data-text);
                position: absolute;
                background-color: #333;
                color: #fff;
                padding: 5px;
                border-radius: 4px;
                white-space: nowrap;
                font-size: 12px;
                top: 140px;
                left: 0;
                -webkit-transform: translateY(-100%);
                -ms-transform: translateY(-100%);
                transform: translateY(-100%);
                z-index: 11;
                pointer-events: none;
                //max-width: 300px;
                overflow: hidden;
                -o-text-overflow: ellipsis;
                text-overflow: ellipsis;
                height: auto;
                width: auto;
            }

            td:hover::after {
                content: attr(data-text);
                position: absolute;
                background-color: #333;
                color: #fff;
                padding: 5px;
                border-radius: 4px;
                white-space: nowrap;
                font-size: 12px;
                top: 140px;
                left: 0;
                -webkit-transform: translateY(-100%);
                -ms-transform: translateY(-100%);
                transform: translateY(-100%);
                z-index: 11;
                pointer-events: none;
                //max-width: 300px;
                overflow: hidden;
                -o-text-overflow: ellipsis;
                text-overflow: ellipsis;
                height: auto;
                width: auto;
            }
        }
    }
}