@import '../settings/index';
@import '../../styles/generic/breakpoints';

#auto-complete-container {
    position: absolute;
    max-height: 14vh;
    width: 85%;
    left: 7%;
    overflow: hidden;
    background-color: $gray-800;
    color: $gray-500;

    /* Estilo general de la barra de desplazamiento */
    ::-webkit-scrollbar {
        width: 10px;
        /* Ancho de la barra de desplazamiento */
    }

    /* Estilo del pulgar (scrollbar thumb) */
    ::-webkit-scrollbar-thumb {
        background-color: #888;
        /* Color del pulgar */
        border-radius: 10px;
        /* Forma elíptica del pulgar */
    }

    /* Estilo al pasar el mouse por encima del pulgar */
    ::-webkit-scrollbar-thumb:hover {
        background-color: #555;
        /* Color del pulgar al pasar el mouse por encima */
    }

    /* Estilo de la barra de desplazamiento cuando está en su extremo superior o inferior */
    ::-webkit-scrollbar-button {
        display: none;
        /* Ocultar las flechas de subir y bajar */
    }

}

#siteTitle {
    display: inline;
    margin: auto 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

}

.suggest-item:hover {


    background-color: $gray-700;
    color: $gray-200;
}

#offCanvasBodyContainer {
    height: 90vh;
}

#hierachyTree {
    display: flex;
    /* flex-flow: column; */
    flex-direction: column;
    height: 100%;
    background-color: #fff;

}

#hierarchySvg {
    //  width: 50vw;
    height: 100%;
    padding: 1.5rem;
    display: flex;
}

#hierarchySvg * {

    display: inline-block
}

#hierarchy-container {

    height: 90vh;
    overflow: auto;
    flex-grow: 1;
    height: auto;
    margin-bottom: 1.5rem;
    // overflow-x: hidden;
    //width: 100%;
}

#hierarchy-container:not(:first-child) {

    /* Set all children of the div to display none */
    >* {
        display: none;
    }

    /* Set the first child of the div to display block */
    >*:first-child {
        display: block;
    }

}

#nodeDetails {
    //   padding-bottom: 9vh;
    //    height: 20%;
    //  padding: 0;
    // margin: 0;
    position: relative;
    bottom: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 3.5rem;
    height: 40%;
    min-height: 35%;
    overflow: auto;

    .card-header {
        span> :first-child {
            padding: 0;
            margin: 0;
        }
    }
}

@keyframes colorChange {
    0% {
        fill: grey;
        //font-size: 0.85em;
        opacity: 0;
    }

    50% {
        fill: green;
        //font-size: 0.85em;
        opacity: 0.5;
    }

    100% {

        fill: black;
        //font-size: 1em;
        opacity: 1;
    }
}

.selected-node-text {
    animation: colorChange 0.5s linear forwards;
}

.parent-selected-node {
    opacity: 1 !important;
}



.parent-selected-node> :nth-child(2) {
    fill: #dee2e6 !important;
}

#search-input {
    margin: 0 auto;
    width: 85%;

}

.hierarchySpinner {
    left: 35%;
}

#hierachySpinner {
    left: 35%;
}

#details-close {
    padding: 0;
}

#moveHierachy {
    position: absolute;
    top: 80%;
}

#eventVolume {
    width: 1px;
    height: 1px;
    visibility: hidden;
    pointer-events: none;
}


.nodeB {
    fill: black;
}

// Aplica estilos para dispositivos móviles
@media (max-width: $mobile) {
    #siteTitle {
        left: 55% !important;

        h3 {
            width: 10ch;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

}

// Aplica estilos para tabletas
@media (min-width: $tablet) and (max-width: $desktop) {
    #siteTitle {
        left: 50% !important
    }
}

// Aplica estilos para escritorios
@media (min-width: $desktop) and (max-width: $large-desktop) {

    #siteTitle {

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        left: 58%;
        text-align: center;
    }
}

// Aplica estilos para pantallas grandes de escritorio
@media (min-width: $large-desktop) {

    #siteTitle {
        left: 45vw;
    }
}

#currentNodeActions.isMobil {
    position: absolute;
    right: 5%;
    top: 50vh;
    width: 5vw;

    button {
        width: auto;
        height: auto;

        svg {
            width: 35px;
            height: 35px !important;
        }
    }
}