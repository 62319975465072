//Variables
@import "./variables";
$mobilFont: 0.6rem;
$mobilFontL: 0.8rem;

.background-primary {
    background-color: $color-primary ;
}

.no-border {
    border: none !important;
}

.main-div {
    width: 100%;
}

.w-20 {
    width: 20%;
}

.w-80 {
    width: 80% !important;
}

.hidden {
    display: none !important;
}

.ellipsis-text {
    white-space: nowrap;
    /* Evita que el texto se divida en varias líneas */
    overflow: hidden;
    /* Oculta el contenido que desborda */
    text-overflow: ellipsis;
    /* Muestra puntos suspensivos al final del texto */
    /* Puedes ajustar el ancho máximo para controlar cuándo se muestran los puntos suspensivos */
    max-width: 90%;
    display: block;
    /* Para que funcione en un elemento de bloque, como un div o un párrafo */
}

.ellipsis-text-vertical {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}

.hidden-element {
    visibility: hidden;
    /* El elemento no se ve, pero sigue ocupando espacio */
    position: absolute;
    /* Mantén el espacio ocupado sin afectar al diseño */
    opacity: 0;
    /* Hacer el elemento completamente transparente */
}

.no-pointer-events {
    pointer-events: none;
}

.pointer-events {

    pointer-events: all;
}

.pointer-hand {
    cursor: pointer;
}

.pointer-forbidden {
    cursor: not-allowed;
}

.no-x-scroll {
    overflow-x: hidden;
}

.input-color {
    background-color: $overlay-color-tertiary;
}

.no-text-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    user-select: none;

}

.no-image-select {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.resize {
    resize: both;
    overflow: hidden;
}

.fade-in {
    animation: fadeInAnimtaion 1s;
}

.link {
    text-decoration: underline;
    color: blue;
    cursor: pointer;
    /* Opcional: Cambia el cursor a una mano para indicar que es un enlace */
    pointer-events: all;
}

.text-bold {
    font-weight: bold;
    //font-size: 1.2em;
    /* Puedes ajustar el tamaño del texto según tus preferencias */
}

.rigth-rounded {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
}

.border-rounded {
    border-radius: 1rem !important;
}

.display-flex {
    display: flex;
}

.flex-colum {
    display: flex;
    flex-flow: column;
    flex-direction: column;
    height: 100vh;
}

.padding-left-1 {
    padding-left: 1rem;
}

@keyframes fadeInAnimtaion {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.center-content {
    display: flex;
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;

}

.font-rem-75 {
    font-size: 0.75rem;
}

.grow-0 {
    flex-grow: 0;
}

.grow-1 {
    flex-grow: 1;
}

.center-absolute-horizontal {
    position: absolute;
    left: 50% !important;
}

.overFlow {
    overflow: auto;
    height: 44vh;
}

.parent-left {
    left: 80%;
}

.parent-right {
    right: 80%;
}