
.spanUploadMap{
    text-align: center;
}

.buttonUploadMap{
    border: none !important;
    background-color: white;
}

.modal-body{
    display: flex;
    flex-direction: column;

    .topBodyModalConfig{
        flex-grow: 1;
        padding-bottom: 1.6vh;
    }

    .bottomBodyModalConfig{
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        .formParentOption{
            flex-grow: 1;
        }

        :nth-child(odd){
            padding-bottom: 1vh;
        }
    }
}

