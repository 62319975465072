//Variables
$color-primary: #4854A5;
$color-secondary: #6F7BC0;
$color-hover: #505ba1;
$color-tertiary: #ffff;
$disabled: #EBEBE4;
$overlay-color-primary: #2A2828;

$overlay-color-secundary: rgba(123, 123, 123, 1);
$overlay-color-tertiary: rgba(206, 212, 218, 1);
$overlay-accent-color: rgba(110, 222, 239, 1);
$overlay-color-light: #e7eef8;

$overlay-color-secundary-tranparent: rgba(123, 123, 123, 0.3);

$overlay-text-primary: #CBCBCB;
$overlay-text-secundary: #FFFFFF;

$color-ancent: rgb(236, 236, 236);
$color-resaltar: #21c93d;
$color-resaltar2: #360A13;

$color-text: rgb(59, 59, 59);
$alt-color-text: #666;
$color-text2: rgb(5, 5, 5);
$alt-color-text2: rgb(255, 255, 255);
//text size
$text-size1: 0.8rem;
$text-size2: 1rem;