@import "../../generic/breakpoints";
@import "../../generic/globalClasses";

.card-actions {
    display: flex
}

.footer-button {
    position: absolute !important;
    top: 90%;
    white-space: pre;
}

#toolbarContainer {
    display: flex;
    height: 70vh;
    width: 30vw;
    position: absolute;
    top: 15%;
    z-index: 1046;

    .card {
        width: 100%;

        .card-header {
            width: 100%;

            .card-label {
                width: 80%;
            }
        }

        .card-body {
            div {
                .list-group {
                    overflow: auto;
                    height: auto;
                }
            }
        }

        .card-footer {

            .card-footer-left {
                margin: 0 auto;
                display: flex;
            }
        }
    }
}

.hierachy-is-open {
    left: 17% !important;
}

.hierachy-is-closed {
    left: 4%;
}

#bodyPanel {
    height: 100%;
}

#toolbarCard {
    height: 110%;

    .card-body {
        display: flex;
        flex-direction: column;

        //  flex-wrap: wrap;
        div:first-child {
            width: auto;
            height: auto;

        }
    }

    .card-body> :first-child {

        // Estilos para el primer hijo

        flex-grow: 0;
        height: auto;
        overflow: auto;

    }

    .card-body>button:first-child {

        // Estilos para el primer hijo

        flex-grow: 0;
    }

}

#containerOptionTools {
    order: 999;
    display: flex;
    justify-content: center;
    background-color: #fff;
    position: absolute;
    width: 90%;
    top: 90%;
    border-radius: 1rem;
    height: auto;
    z-index: 1;
    margin: 0 auto;
    margin-bottom: 1rem;

    .btn-group {
        button {
            width: auto;

            font-size: 1rem;

        }
    }
}


#containerOptionTools.isMobile {
    //background-color: orange;
    width: 10%;
    //text-orientation: upright;
    //font-size: 18px;
    padding: 0;
    position: relative;
    top: 0;
    left: -25%;
    z-index: -1;

    .card-footer-left {
        height: auto;
        position: absolute;
        left: 4.5vw;
        width: 15vw;

        .btn-group-vertical {
            height: auto;
            background-color: #fff;
            border-radius: 1rem;
            width: auto;

            span {
                writing-mode: vertical-lr;
            }

            button {
                .btn-icon {
                    margin-right: 0;
                    margin-bottom: 0.5rem;
                }

                margin-bottom: 1px;
            }
        }

        .btn-group {
            writing-mode: vertical-lr;
            background-color: #fff;
            width: 100%;

            button {
                display: flex;
                //flex-direction: column;
                align-items: center;
                padding-left: 12%;

                .btn-icon {
                    margin-right: 0;
                    margin-bottom: 0.5rem;
                }

                margin-bottom: 1px;
            }

            button:first-child {
                border-radius: 1rem;
            }
        }
    }
}

#save-bar {

    /*
    display: flex;
    justify-content: space-around;
    align-items: center;
    */
    /* flex-flow: row nowrap; */
    /*
    flex-direction: row;
    flex-wrap: nowrap;
    
*/

    align-content: stretch;
    pointer-events: none;
    width: 100%;
    height: auto;
    width: auto;
    gap: 20px;
    //left: -2%;
    position: inherit;
    // top: -7.2%;

    div {
        width: auto;
        height: auto;
        border-radius: 5px;


        button {
            pointer-events: all;
            width: 120px;
            vertical-align: middle;
            position: relative;
            top: 0%;
            right: 0%;
            //transform: translateY(-50%);
        }
    }
}

#save-bar> :first-child {
    float: right;
    margin-left: auto;
}

.list-group-item {
    display: flex;

    span {
        flex-grow: 1;
    }

    div {
        flex-grow: 0;

        .btn {
            margin-left: 10px;
            margin-right: 10px;
        }
    }
}

#detailsList {
    // height: 32vh;
    overflow-y: auto;
    min-height: 5vh;

    .title {
        display: flex;

        div {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}


#detailsList .list-group-item {
    display: flex;
    flex-direction: row;
    gap: 2%;

    :first-child {
        flex-grow: 0;
    }

    :nth-child(2) {
        flex-grow: 1;
    }
}

#propertyList {
    display: flex;
    flex-direction: row;
    gap: 1%;

    background: #fdfdfd00;

    :first-child {
        flex-grow: 1;

    }

    :nth-child(2) {
        flex-grow: 1;

    }
}

#sensorButtom {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    div {
        width: calc(55% - 10%);
        margin-bottom: 0.5rem;
        /* Otros estilos para tus hijos div */
    }
}



#editPositionTools {
    position: absolute;
    z-index: 1;
    bottom: -90vh;
    left: 43.5%;
    margin-bottom: 2rem;
}

#externalUrl {
    margin-bottom: 0.5rem;
}

.detailsDeleteButton {
    position: absolute !important;
    right: 0px;
    top: 20%;
    background: white;
}




@media((min-width: $mobile) and (max-width: $tablet )) {
    #modelManager {
        height: 100%;
        position: relative;
        background-color: #fff;
        bottom: 30%;
    }

    #toolbarContainer {
        left: 9%;
        width: 50vw;
        top: 20%;
        height: 50vh;

        .isMobile {
            background: transparent;
            width: 25%;
            left: -20%;
            height: 70vh;
            margin: 0;
        }

        .isMobile .card-footer-left {
            height: auto;
            position: relative;
            left: 40%;
            width: 100%;

            .btn-group button {
                display: -ms-flexbox;
                display: flex;
                -ms-flex-align: center;
                align-items: center;
                padding-left: 5%;
                margin-bottom: 1px;
                height: 14vh !important;
                font-size: $mobilFont;
            }
        }
    }

    #containerOptionTools {
        width: 2vw;
        height: auto;
        margin: 0;

        card-footer-left {
            height: auto;
            position: absolute;
            left: 4.5vw;
            width: 7vw;
        }
    }

    .card-header {
        flex-direction: initial;
    }

    #toolbarCard {

        .card-body {
            div {
                button {
                    position: relative;
                    bottom: -10%;
                    font-size: $mobilFontL;
                }
            }
        }

        .card-header {


            h5 {
                font-size: $mobilFont;

            }
        }

        *>h5 {
            font-size: $mobilFont;

        }

    }

    #detailsList {
        height: 45% !important;

        div {
            height: auto !important;
        }
    }

    #detailsList>div * {}

    .btn btn-primary {
        font-size: $mobilFontL;
    }

    .propertyList {
        input {
            font-size: $mobilFont;

        }

        button {
            padding: 0;
            margin: 0;
            font-size: $mobilFontL;

            .svg-icon--material {
                height: auto;
                width: 65%;
                padding-bottom: 40%;
            }
        }
    }

    #save-bar {
        position: relative;
        bottom: 12.5%;
        width: 40%;
        left: 52%;
        height: auto;

        div {
            height: auto;

            button {
                position: relative;
                top: 0%;
                right: 0%;
                height: 2.5rem !important;
                margin: 0;
                padding: 0;
                font-size: $mobilFontL;
            }
        }
    }

    .list-group {
        height: 25% !important;


    }

    .list-group-item {
        height: 3.5rem;
        /* padding-bottom: 0.75rem; */
        /* padding-top: 0.75rem; */
        width: 100%;
        font-size: $mobilFont;
        width: 100%;

        span {
            font-size: $mobilFont;
            padding-top: 1.5rem;
        }

        div {
            margin-top: auto;
        }
    }



    div {


        button {
            height: auto !important;

            svg {
                height: 1.5rem;
                width: auto;
            }
        }
    }

    #toolbarCard {
        width: 50vw;

        /*
        .card-body div:first-child {
            height: 40%;
        }
*/
        .card-header {
            .card-actions {
                display: flex !important;
                flex-direction: row;
                width: 100%;
                justify-content: flex-end;

                button {
                    height: auto !important;
                    width: 35%;
                    margin-left: 1rem;
                }

                .btn-light-danger {
                    //  position: relative;
                    //  left: 50%;
                }
            }
        }

        #bodyPanel {
            div {
                .list-group {
                    display: flex;
                    align-items: center;
                    width: 100%;

                    .list-group-item span {
                        margin: auto;
                        padding: 0;
                        font-size: $mobilFont;

                    }

                    div {
                        button {
                            margin-right: 0.2rem;
                        }
                    }
                }

                div {

                    >* {
                        height: 1.5rem;
                        font-size: $mobilFont;
                    }

                    .form-label {
                        margin-bottom: 0rem;
                    }

                    select {
                        padding-top: 0;
                        padding-bottom: 0;
                    }
                }
            }


        }
    }

    #editPositionTools {
        position: absolute;
        left: 23%;
        bottom: 0%;
        z-index: 2;

        .btn-group button {
            font-size: $mobilFont;
        }

    }

    #detailsEdit3d {
        position: absolute !important;
        left: 31.5% !important;
        background-color: transparent !important;
        margin-top: 0.5rem !important;
        height: auto !important;
        width: 35vw !important;
        border: none !important;
        bottom: 0% !important;

        .card {
            .card-header {
                height: 1.5rem;

                .card-label .card-title-wrapper h5,
                h6 {
                    font-size: $mobilFontL;

                }
            }

            .card-footer {
                height: auto;
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                flex-direction: row;

                .card-footer-left button {
                    font-size: $mobilFont;

                }

                .card-footer-right button {
                    font-size: $mobilFont;

                }

                >* {
                    margin: 0 !important;
                }
            }
        }
    }
}

@media ((max-width: $mobile) and (min-width: $mobileS )) {
    #modelManager {
        height: 100%;
        position: relative;
        background-color: #fff;
        bottom: 30%;
    }

    #hierarchyButtonGroup {

        padding: 0;
        margin: auto 0;

        button {
            padding: 0.5rem;
        }
    }

    #toolbarCard .card-body div:first-child {
        width: 100%;
    }

    #toolbarContainer {
        left: 9%;
        width: 60vw;
        top: 20%;
        height: 50vh;

        .isMobile {
            background: transparent;
            width: 2vw;
            left: 0%;
            height: 70vh;
            margin: 0;
        }

        .isMobile .card-footer-left {
            height: auto;
            position: relative;
            left: 40%;
            width: 10vw;

            .btn-group button {
                display: -ms-flexbox;
                display: flex;
                -ms-flex-align: center;
                align-items: center;
                padding-left: 5%;
                margin-bottom: 1px;
                height: 14vh;
                font-size: $mobilFont;
            }
        }
    }

    #containerOptionTools {
        width: 2vw;
        height: auto;
        margin: 0;

        card-footer-left {
            height: auto;
            position: absolute;
            left: 4.5vw;
            width: 7vw;
        }
    }

    .card-header {
        flex-direction: initial;
    }

    #toolbarCard {
        .card-header {
            padding: 1rem 1rem 0.5rem 1rem !important;


            .card-title {
                margin-bottom: 0;
                margin-top: 0;
            }
        }

        .card-body {
            padding: 0rem 1rem 1rem 1rem !important;

            div {
                button {
                    //  position: relative;
                    bottom: -10%;
                    font-size: $mobilFontL;
                }
            }
        }

        .card-header {


            h5 {
                font-size: $mobilFont;

            }
        }

        *>h5 {
            font-size: $mobilFont;

        }

    }

    #detailsList {
        // height: 45% !important;

    }

    #detailsList>div * {}

    .btn btn-primary {
        font-size: $mobilFontL;
    }

    .propertyList {
        input {
            font-size: $mobilFont;

        }

        button {
            padding: 0;
            margin: 0;
            font-size: $mobilFontL;

            .svg-icon--material {
                height: auto;
                width: 65%;
                padding-bottom: 40%;
            }
        }
    }

    #save-bar {
        position: relative;
        bottom: 0%;
        width: 100%;
        left: 0%;
        height: auto;

        div {
            height: auto;

            button {
                position: relative;
                top: 0%;
                right: 0%;
                height: 2.5rem !important;
                margin: 0;
                padding: 0;
                font-size: $mobilFontL;
            }
        }
    }

    .list-group {
        //  height: 20vh !important;


    }

    .list-group-item {
        height: 3.5rem;
        /* padding-bottom: 0.75rem; */
        /* padding-top: 0.75rem; */
        width: 100%;
        font-size: $mobilFont;

        span {
            font-size: $mobilFont;
            padding-top: 1.5rem;
        }
    }

    .list-group-item>div:first-of-type {
        /* Estilos para el primer hijo (ignora elementos no div) */
        display: flex;

    }


    div {


        button {
            height: auto !important;

            svg {
                height: 1.5rem;
                width: auto;
            }
        }
    }

    #toolbarCard {
        width: 50vw;

        .card-header {
            .card-actions {
                display: flex !important;
                flex-direction: row;
                width: 100%;
                justify-content: flex-end;

                button {
                    height: auto !important;
                    width: 35%;
                    margin-left: 1rem;
                }

                .btn-light-danger {
                    //  position: relative;
                    //  left: 50%;
                }
            }
        }

        #bodyPanel {

            div {
                .list-group {
                    display: flex;
                    align-items: center;
                    width: 100%;

                    .list-group-item span {
                        margin: auto;
                        padding: 0;
                        font-size: $mobilFont;

                    }

                    div {
                        button {
                            margin-right: 0.2rem;
                        }
                    }
                }

                div {

                    >* {
                        height: 1.5rem;
                        font-size: $mobilFont;
                        width: 100%;

                    }

                    .form-label {
                        margin-bottom: 0rem;
                    }

                    select {
                        padding-top: 0;
                        padding-bottom: 0;
                    }
                }
            }

        }


    }

    #editPositionTools {
        position: absolute;
        left: 10%;
        bottom: 0%;
        z-index: 2;

        .btn-group button {
            font-size: $mobilFont;
        }

    }

    #detailsEdit3d {
        position: absolute !important;
        left: 31.5% !important;
        background-color: transparent !important;
        margin-top: 0.5rem !important;
        //  height: auto !important;
        width: 35vw !important;
        border: none !important;
        bottom: 0% !important;

        position: absolute !important;
        left: 46.5% !important;
        background-color: transparent !important;
        margin-top: 0.5rem !important;
        height: 31vh !important;
        width: 52vw !important;
        border: none !important;
        /* bottom: -11% !important; */
        top: 70px;

        .card {
            .card-header {
                height: 1.5rem;

                .card-label .card-title-wrapper h5,
                h6 {
                    font-size: $mobilFontL;

                }
            }

            .card-footer {
                height: auto;
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                flex-direction: row;

                .card-footer-left button {
                    font-size: $mobilFont;

                }

                .card-footer-right button {
                    font-size: $mobilFont;

                }

                >* {
                    margin: 0 !important;
                }
            }
        }
    }

    .toolbarCard-hierarchy-lock {
        background-color: orange;
    }

}

#modelManager {
    ul {
        max-height: 100%;
    }
}

@media (min-width: $tablet) {

    #toolbarContainer {
        left: 9%;
        width: 50vw;
        height: 60vh;
    }

    #containerOptionTools.isMobile .card-footer-left {
        height: auto;
        position: absolute;
        left: 4.5vw;
        width: 7vw;
    }
}

@media (max-width: $desktop) {

    #toolbarContainer {
        left: 9%;
        width: 50vw;
        height: 60vh;

    }

    #containerOptionTools.isMobile .card-footer-left {
        height: auto;
        position: absolute;
        left: 5vw;
        width: 7vw;
    }

    #toolbarContainer.toolbarContainer-hierarchy-lock {
        position: absolute;
        left: 25% !important;
        top: 10vh !important;
    }
}


@media (min-width: $desktop) {

    /*
    #modelManager {

        height: 100%;
        position: fixed;
        background-color: #fff;
        bottom: 17%;
        width: inherit;
         width: 31%; 
      width: 33vw;
     height: 60vh;
    
}
*/
    #containerOptionTools {
        //  background-color: deeppink;
    }

    #toolbarContainer {
        left: 22%;
        width: 35vw;
        font-size: 1.5rem;

    }

    #hierarchyButtonGroup {
        height: auto;
        font-size: 1.5rem !important;
        padding: 0;
        /*
        button {


            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0;
            padding: 0;

            >* {
                font-size: 0.75rem;
            }

            span {
                line-height: 2rem;
            }

            svg {
                margin-top: 5%;
            }
        }
        */
    }
}

@media (min-width: $tablet) and (max-width:$desktop) {
    #detailsEdit3d {
        position: absolute;
        left: 34% !important;
        background-color: transparent;
        margin-top: 0.5rem;
        height: auto;
        width: auto;
        -webkit-box-shadow: none;
        border: none;
        bottom: 35%;
    }

    #editPositionTools {
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 32%;
        margin-bottom: 2rem;
    }

    #modelManager {
        height: 100%;
        position: relative;
        background-color: #fff;
        bottom: 30%;
    }

    #toolbarContainer {
        left: 9%;
        width: 35vw;
        font-size: .7rem !important;
        top: 15%;

        button {
            font-size: 0.5rem !important;
            height: auto;
            width: auto;
            padding: 0.5rem;

        }
    }

    #hierarchyButtonGroup {
        height: auto;
    }
}

@media (min-width: $large-desktop) {
    #modelManager {
        height: 100%;
        position: relative;
        background-color: #fff;
        bottom: 33%;
    }

    #toolbarContainer.isMobile {

        width: 35vw;
        position: absolute;
        left: 7%;
        top: 13%;
    }

    #toolbarContainer {
        left: 6%;
        width: 35vw;
    }
}

#modelFIleInputModal {


    form {
        display: flex;
        flex-direction: column;
        height: 100%;





    }


}


#ModelFileInputContainer {
    flex: 1;

    button {
        margin-top: 1.5rem;
        flex: 1;
        /* Otras propiedades de estilo para el primer hijo */
    }

}


.toolbarContainer-hierarchy-lock {
    top: 150% !important;
    left: 2% !important;
}

@media (min-width: $desktop) and (max-width:$large-desktop) {
    #containerOptionTools.isMobile {
        //background-color: orange;
        width: 10%;
        //text-orientation: upright;
        //font-size: 18px;
        padding: 0;
        position: absolute;
        top: 0% !important;
        left: 28vw;
        z-index: -1;

        .card-footer-left {
            height: auto;
            position: absolute;
            left: 4.5vw;
            width: 6vw !important;

            .btn-group-vertical {
                height: auto;
                background-color: #fff;
                border-radius: 1rem;
                width: auto;

                span {
                    writing-mode: vertical-lr;
                }

                button {
                    .btn-icon {
                        margin-right: 0;
                        margin-bottom: 0.5rem;
                    }

                    margin-bottom: 1px;
                }
            }

            .btn-group {
                writing-mode: vertical-lr;
                background-color: #fff;
                width: 100%;

                button {
                    display: flex;
                    //flex-direction: column;
                    align-items: center;
                    padding-left: 12%;

                    .btn-icon {
                        margin-right: 0;
                        margin-bottom: 0.5rem;
                    }

                    margin-bottom: 1px;
                }

                button:first-child {
                    border-radius: 1rem;
                }
            }
        }
    }
}