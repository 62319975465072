@import "../../styles/generic/variables";
@import "../../styles/generic/breakpoints";
@import "../../styles/generic/globalClasses";

.iframe-no-border {
    border: none;
}

.hide {
    display: none;
}

.container-matterport {
    height: 100%;
}

.matterport {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: rebeccapurple;
}



.layerFront {
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    pointer-events: none;

    .leftLayer {
        flex-grow: 1;
        width: 80%;
        height: 100%;
    }

    .rightLayer {
        flex-grow: 0;
        width: 20%;
        height: 100%;

        display: flex;
        flex-direction: column;

        .topRightLayer {
            flex-grow: 1;
            height: 60%;
            width: 100%;
        }

        .bottomRightLayer {

            flex-grow: 0;
            height: 40%;
            width: 100%;
        }
    }
}

#detailsEdit3d {
    position: absolute;
    left: 42.5%;
    background-color: transparent;
    margin-top: 0.5rem;
    height: auto;
    width: auto;
    //background-color: $overlay-color-light;
    -webkit-box-shadow: none;
    border: none;


    .card {



        .card-header {
            margin: 0;
        }


    }


    .card {
        .card-header {
            .card-label {
                max-width: 100%;

                .card-title-wrapper {
                    width: 100%;

                    .card-title {
                        margin-bottom: 0;
                        text-align: center;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 100%;
                    }
                }
            }
        }
    }

}

@media (max-width: $mobile) {
    #app-container {
        //padding-top: 5rem;
        margin-top: 5rem;
    }
}


@media (min-width: $tablet) {
    #app-container {
        //     padding-top: 5rem !important;

        margin-top: 5rem;
    }
}

@media (max-width: $desktop) {
    #app-container {
        //     padding-top: 5rem !important;
        margin-top: 5rem;
    }
}

@media (min-width: $desktop) {
    #app-container {
        // padding-top: 5rem !important;
        margin-top: 0rem;
    }
}

#app-container {
    padding-bottom: 1.2rem;


}

.matterport-hierachy-lock {

    position: relative;
    width: 83vw;
    left: 13vw;
}