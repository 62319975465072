.matterport {
    height: 100%;
}

.pointer-hand {
    cursor: pointer;
}

.hidden {
    display: none;
}

.visible {
    display: block;
}

.pointer-events {
    pointer-events: all;
}

.no-pointer-events {
    pointer-events: none;
}

.rounded-div {
    border-radius: 10%;
}

.spinner {
    position: relative;
    top: 50%;
    z-index: 100;
    left: 50%;
}