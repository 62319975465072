.halfSizeTwoElements {
    width: 50%;
}

.halfSizeTwoElementsFirstElement {
    width: 50% !important;
}

.propertyList {


    input:first-child {
        width: 10% !important;
        display: initial;
        flex: inherit;
    }
}